import React, {useState, useEffect} from 'react'
import {Box, Button, Dialog, DialogContent} from '@mui/material';
import './offers.css';
import {submitBesoins} from "../../api/service";
import {Langage} from "../../language";

const Offers = (props) => {
    const [num, setNum] = useState(null);
    const [offreOpen, setOffreOpen] = useState(false);
    const [showNext, setShowNext] = useState(false);

    const myApp = 'jess';
    const myLang = 'english'
    const getText = (key) => {
        return Langage[key][myLang]
    }

    const playAnim = () => {
        for (let i = 0; i < 101; i++) {
            setTimeout(() => {
                setNum(i);
                if(i === 3) {
                    document.querySelector('#offre-prep').scrollIntoView();
                }
                if(i === 20) {
                    setOffreOpen(true);
                }
                if(i === 80) {
                    setOffreOpen(false);
                }
                if(i === 100) {
                    showVideo();
                }
            }, 200 * i);
        }
    }

    const showVideo = () => {
        setShowNext(true);
        setTimeout(() => {
            document.querySelector('#offre-prep-end').scrollIntoView();
            let video =  document.getElementById('vid');
            video.src = `assets/videos/video-7-${myApp}-${myLang}.mp4`;
            video.style.display = 'block';
            video.play();
            setTimeout(() => {
                video.style.display = 'none';
            }, 4000)
        }, 800)
    }

    useEffect(() => {
        setShowNext(false);
        let video =  document.getElementById('vid');
        video.src = `assets/videos/video-4-${myApp}-${myLang}.mp4`;
        video.style.display = 'block';
        video.play();
        setTimeout(() => {
            video.style.display = 'none';
        }, 11000)

        setTimeout(() => {
         playAnim();
        }, 11000);
    }, [])

    return (
        num && <Box>
            <div className={'pub-img'}>
                <span className={'num'}>{getText('presc_on_way')}</span>
                <div className="progress-bar">
                    <span>{num} %</span>
                </div>
                <progress value={num} max="100"></progress>
                <div className={'offre-prep'} id={'offre-prep'}>
                    <span>{getText('see_our_offer')}</span>
                </div>
            </div>
            {
                offreOpen && <Dialog open={offreOpen}>
                    <DialogContent>
                        <div className={'selfy-img'}>
                            <img src={'/assets/images/pub/' + props.state.skinType + '.png'} alt="pub" className={'pub'}/>
                        </div>
                    </DialogContent>
                </Dialog>
            }
            {
                showNext && <div id={'offre-prep-end'}>
                    <div className={'offre-prep'}>
                        <span>{getText('i_have_products')}</span>
                    </div>
                    <Button variant="outlined" onClick={ () => {submitBesoins(props.state)}}>{getText('see_ma_selection')}</Button>
                </div>
            }
        </Box>
    )
}

export default Offers
