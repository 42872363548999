import {LangageHair} from "./language";

const myLang = 'english'
const getText = (key) => {
    return LangageHair[key][myLang]
}

export const haireQt = [
    {
        "uid": 0,
        "brief": getText('brief_type'),
        "question": getText('hair_type'),
        "choices": [
            getText('steep'),
            getText('corrugated'),
            getText('looped'),
            getText('curly'),
            getText('frizzy')
        ],
        "min_len": 5
    },
    {
        "uid": 1,
        "brief": getText('brief_thick'),
        "question": getText('hair_thickness'),
        "choices": [
            getText('fins'),
            getText('normals'),
            getText('epais')
        ],
        "min_len": 4
    },
    {
        "uid": 2,
        "brief": "Cuir chevelu: ",
        "question": getText('scalp'),
        "choices": [
            getText('sec'),
            getText('normal'),
            getText('gras')
        ],
        "min_len": 3
    },
    {
        "uid": 3,
        "brief": getText('brief_beauty'),
        "question": getText('goals'),
        "choices": [
            getText('volume'),
            getText('smoothing'),
            getText('loops'),
            getText('shine')
        ],
        "min_len": 15
    },
    {
        "uid": 4,
        "brief": getText('brief_soin'),
        "question": getText('hair_care'),
        "choices": [
            getText('growth'),
            getText('strengthen'),
            getText('appease'),
            getText('hydrate'),
            getText('feed'),
            getText('repair'),
            getText('sebum')
        ],
        "min_len": 15
    },
    {
        "uid": 5,
        "brief": "",
        "question": getText('product_type'),
        "choices": [
            getText('shampoo'),
            getText('conditioner'),
            getText('lotion')
        ],
        "min_len": 6
    },
    {
        "uid": 6,
        "brief": "",
        "question": getText('spec_need'),
        "choices": [
            getText('colored_hair'),
            getText('others')
        ],
        "min_len": 0
    },
    {
        "uid": 7,
        "brief": "",
        "question": getText('recommend_products'),
        "choices": [getText('see_selection')],
        "widget": "multiChoices",
        "min_len": 0
    }
]
